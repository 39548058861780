.container {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 15px 50px;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.container[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.45);
}
.container span {
  width: 176px;
  height: 38px;
  font-family: var(--font-family-light);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.container[data-theme="light"] span {
  color: var(--text-color-secondary-light);
}
.container p {
  width: 165px;
  height: 38px;
  margin-left: 36px;

  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.icon_wrapper {
  width: 64px !important;
  height: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 16px 0 24px;
  background-color: #259eff;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
