.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  margin: 0 auto;
}

.weekdays {
  min-width: 148px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  font-family: var(--font-family-medium);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.weekdays[data-theme="light"] {
  background-color: rgba(85, 4, 217, 0.05);
  color: var(--text-color-primary-light);
}

.day_card {
  min-width: 148px;
  padding: 8px 4px;
  height: 178px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
.day_card[data-theme="light"] {
  background-color: rgba(56, 56, 116, 0.02);
}
.day_card[data-isholiday="true"] {
  border: 1px solid rgba(85, 4, 217, 0.6);
}
.day_card[data-isholiday="true"][data-theme="light"] {
  border: solid 1px rgba(85, 4, 217, 0.3);
}
.day_card_content[data-visible="true"] {
  display: none;
}
.day {
  position: relative;
  margin: 0 0 8px 0;
  font-family: var(--font-family-medium);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.day[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.day span {
  cursor: pointer;
}
.info_icon {
  position: absolute;
  right: 0;
  top: -2px;
}
.info_icon img {
  width: 20px;
  height: auto;
}
.bar_wrapper {
  width: 32px;
  height: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 173, 146, 0.1);
  border-radius: 1em;
  position: absolute;
  top: 6px;
  left: 0px;
  overflow: hidden;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  border-radius: 12px 0 0 12px;
  font-size: 9px;
}
.carrier {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(11, 2, 43, 0.1);
  font-size: 14px;
  font-family: var(--font-family-medium);
}
.carrier[data-first="true"] {
  background-color: rgba(200, 176, 255, 0.02);
}
.carrier[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.carrier_code {
  display: flex;
  align-items: center;
  min-width: 32px;
  color: var(--text-color-primary);
}
.carrier_code[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.is_connecting_dot {
  background-color: rgb(20, 74, 255);
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 4px;
}
.class_code {
  display: flex;
  min-width: 16px;
  color: var(--text-color-secondary);
}
.class_code[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.price_wrapper {
  display: flex;
  gap: 4px;
  color: var(--text-color-primary);
}
.price_wrapper[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.currency {
  display: flex;
  min-width: 32px;
}
.price {
  display: flex;
  min-width: 32px;
  max-width: 32px;
}
.arrow {
  width: 8px;
  height: 10px;
  margin-left: 4px;
}
