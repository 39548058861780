.button_wrapper {
  margin: 1rem;
}
.back_button {
  width: 84px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 1em;
  color: var(--text-color-active);
  cursor: pointer;
}
.arrow_icon {
  width: 16px !important;
  height: 16px !important;
}
.children {
  overflow: auto;
}
