.container {
  color: var(--text-color-primary);
}
.content {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  color: var(--text-color-primary);
  margin: 4px 0;
  overflow: hidden;
}
.category,
.sub_categories,
.event {
  display: flex;
  gap: 8px;
  padding: 12px 1em;
  position: relative;
}
.category {
  padding-left: 8px;
}
.event {
  border-bottom: 1px solid var(--border-color);
}
.event:last-child {
  border-bottom: none;
}
.sub_categories {
  padding-left: 2em;
  background-color: rgba(255, 255, 255, 0.1);
}
.event {
  padding-left: 4em;
  background-color: rgba(255, 255, 255, 0.05);
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sub_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hr {
  margin: 0;
  color: var(--border-color);
}
.selected_count {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: 14px;
  font-size: 11px;
  background-color: #5504d9;
  border-radius: 50%;
  padding-top: 2px;
}
.selected_count[data-active="true"] {
  visibility: hidden;
}
.selected_count_category {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;
  font-size: 11px;
  background-color: #5504d9;
  border-radius: 50%;
  padding-top: 2px;
}

.selected_count_category[data-active="true"] {
  visibility: hidden;
}


