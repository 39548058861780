.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 0 28px 16px;
}
.open_menu_wrapper {
  position: relative;
  z-index: 6;
}
.setting_icon {
  width: 48px;
  height: 48px;
  margin-left: 4px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.open_menu {
  width: 232px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  margin-top: 8px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_top {
  width: 216px;
  height: 124px;
  padding: 24px 20px 20px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
}
.open_menu_top[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.open_menu_title_wrapper {
  display: flex;
  justify-content: space-between;
}
.open_menu_title {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.open_menu_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.open_menu_icons {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.open_menu_icons[data-theme="light"] {
  opacity: 0.6;
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.open_menu_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.open_menu_button {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.open_menu_buttons[data-active="true"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-family-medium);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.open_menu_buttons[data-active="true"][data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-secondary-light);
  background-color: var(--button-bg-color-active-primary-light);
}
.open_menu_buttons[data-active="false"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.open_menu_buttons[data-active="false"][data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-secondary-light);
  background-color: var(--button-bg-color-primary-light);
}
.range_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.card_content {
  padding: 16px;
  display: flex;
  align-items: center;
}
.business_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.content_title {
  width: 150px;
  height: 15px;
  margin-left: 8px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.range_slider_wrapper {
  padding: 0 24px;
}

.temporary_container {
  display: flex;
  flex-direction: column;
}
.temporary_container div {
  width: 100%;
  height: auto;
}
.temporary_container div img {
  width: 100%;
  height: auto;
}
