.container {
  width: 100%;
  display: flex;
  align-items: center;
}
.range_date_format {
  word-wrap: break-word;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
