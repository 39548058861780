.button {
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1px;
  border-radius: 5px;
  backdrop-filter: blur(31px) saturate(150%);
  -webkit-backdrop-filter: blur(31px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  padding: 5px;
  font-family: var(--font-family-medium);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.button[data-theme="light"] {
  border: solid 1px rgba(28, 28, 37, 0.2);
  background-color: transparent;
}
.button[data-active="true"] {
  height: 32px;
  margin: 1px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  padding: 5px;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.1);
}
.button[data-active="true"][data-theme="light"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  border: solid 1px rgba(28, 28, 37, 0.4);
  background-color: rgba(28, 28, 37, 0.05);
}
.disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.1);
}
