.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}
.card_title {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 20px;
  color: var(--text-color-primary);
}
.export_button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  cursor: pointer;
}
.export_button img{
  width: 24px;  
  height: 24px;
}