.container {
  /* max-width: 1800px; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 18px;
}
.card_title {
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_body {
  display: flex;
  gap: 8px;
}
.rules_creator_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 0 12px;
  margin-bottom: 16px;
}

.rules_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form_element {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0 12px;
}
.form_labels {
  width: 120px;
  height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 8px;
}
.form_labels[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.rule_id,
.rule_name,
.description,
.notes {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.rule_id:focus,
.rule_name:focus,
.description:focus,
.notes:focus {
  outline: solid 0.5px rgba(255, 255, 255, 0.1);
}
.rule_name {
  width: 300px;
  height: 40px;
  padding: 12px;
  border-radius: 10px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 5px;
}
.rule_name[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.rule_id {
  width: 300px;
  height: 40px;
  padding: 12px;
  border-radius: 10px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.rule_id[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.description {
  max-width: 300px;
  min-width: 300px;
  max-height: 153px;
  min-height: 153px;
  padding: 12px;
  border-radius: 10px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.description[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.notes {
  max-width: 300px;
  min-width: 300px;
  max-height: 153px;
  min-height: 153px;
  padding: 12px;
  border-radius: 10px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.notes[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.divider {
  margin: 24px 0 !important;
  background-color: rgba(255, 255, 255, 0.3);
}
.datepicker_wrapper {
  margin-top: 25px;
}
.error {
  border: 1px solid red;
}
.forever_wrapper {
  display: flex;
  justify-content: center;
}
.buttons_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.forever_wrapper[data-theme="light"] .buttons_wrapper {
  background-color: var(--button-bg-color-primary-light);
}
.button_forever {
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
}
.button_forever[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.2);
}
.forever_wrapper[data-theme="light"] .button_forever[data-active="true"] {
  background-color: #fff;
}
.forever_text {
  font-size: 1.3em;
  position: relative;
  top: 2px;
  margin-left: 4px;
}
