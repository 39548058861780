.container {
  width: 100%;
  min-height: 420px;
  overflow: hidden;
}
.table_wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 420px;
  position: relative;
}
.data_not_found_text {
  position: absolute;
  left: calc(50% - 54px);
  top: calc(50% - 12px);
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.thead {
  width: 100%;
  height: 32px;
  background-color: rgb(17, 12, 32);
  position: sticky;
  top: 0px;
  z-index: 2;
}
.thead[data-theme="light"] {
  background-color: #cbbbef;
}
.hoc {
  cursor: grab !important;
}

.tbody {
  position: relative;
}
.tr {
  height: 48px;
  background-color: rgba(255, 255, 255, 0.05);
}
.tr[data-nth="true"] {
  background-color: rgba(85, 4, 217, 0.1);
}
.tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.tr[data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.05);
}
.tr_margin {
  height: 4px;
}
.th {
  white-space: nowrap;
  position: relative;
}
.th,
.td {
  padding: 0 16px;
}
.td {
  height: 48px;
}
.td:first-child,
.th:first-child {
  border-radius: 8px 0 0 8px;
}
.td:last-child,
.th:last-child {
  border-radius: 0 8px 8px 0;
}
.col_wrapper {
  display: inline-flex;
  gap: 4px;
  position: relative;
}
.col_name {
  height: 18px;
  font-size: 12px;
  color: var(--text-color-secondary);
}
.col_name[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.filtered_icon {
  width: 14px !important;
  height: 14px !important;
  position: relative;
  top: 1px;
}
.menu_icon_wrapper {
  visibility: hidden;
  position: relative;
}
.th:hover .menu_icon_wrapper {
  visibility: visible;
}
.mui_icon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  position: relative;
  top: -4px;
}
.filter_sort_menu_wrapper {
  position: absolute;
  left: 0;
  top: 32px;
  z-index: 2;
}
.filter_sort_menu {
  display: none;
  flex-direction: column;
  gap: 8px;
  background-color: rgb(63, 54, 90);
  padding: 8px;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}
.filter_sort_menu[data-active="true"] {
  display: flex;
}
.filter_sort_menu[data-theme="light"] {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.menu_items {
  width: 450px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.multi_sort_button,
.add_filter_button,
.filter_menu_apply_button {
  border: none;
  outline: none;
  border-radius: 4px;
}
.filter_menu_close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding: 8px 8px 4px 0;
}
.filter_menu_reset_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter_menu_reset_button {
  display: flex;
  align-items: center;
  margin: 8px;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
  opacity: 1;
}
.filter_menu_reset_button[data-active="false"] {
  opacity: 0.3;
}
.filter_column_name {
  width: 150px;
}
.select_input {
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
}
.add_filter_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 16px;
}
.input {
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  height: 32px;
  padding: 4px 4px 4px 8px;
  color: var(--text-color-secondary);
}
.pagination_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}
.select_option {
  outline: none;
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 8px;
}
.pagination_info {
  margin-left: 8px;
}
.filter_menu_container {
  width: 200px;
  background-color: rgb(63, 54, 90);
  position: absolute;
  top: 32px;
  z-index: 10;
  border-radius: 4px;
}
.filter_menu_container[data-theme="light"] {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.filter_menu_search_input {
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  border: none;
  outline: none;
}
.filter_menu_search_input[data-theme="light"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.filter_menu_items {
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}
.filter_item {
  display: flex;
  align-items: center;
  max-width: 90%;
  border-radius: 0 8px 8px 0;
  padding: 8px 8px 8px 16px;
}
.filter_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.filter_item[data-theme="light"]:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.filter_item_label {
  width: 100%;
  position: relative;
  top: 1px;
  padding-left: 8px;
}
.filter_menu_apply_button {
  width: 100%;
  height: 32px;
}
.expand_button {
  display: flex;
  align-items: center;
  padding-left: 8px;
}
.expand_button .mui_icon {
  position: relative;
  top: 1px;
  transition: ease-in-out 100ms;
}

.group_menu_container {
  width: 330px;
  height: 305px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background-color: rgb(63, 54, 90);
  position: absolute;
  left: 0;
  top: 24px;
  z-index: 5;
}
.group_menu_container[data-active="false"] {
  display: none;
}
.group_menu_search {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
}
.group_menu_search input {
  width: 100%;
  padding: 4px 4px 4px 28px;
  outline: none;
  border: none;
  border-radius: 8px;
}

.group_menu_search .search_icon {
  position: absolute;
  left: 12px;
}
.group_menu_search[data-theme="dark"] .search_icon {
  color: black;
}
.group_menu_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}
.group_menu_buttons button {
  width: 100px;
  border-radius: 8px;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.label {
  margin-left: 8px;
}

.expand_icon {
  cursor: pointer;
}
.group_menu_apply_button {
  padding: 8px;
}
.group_menu_apply_button button {
  width: 100%;
  border-radius: 8px;
}
.sub_items {
  padding: 12px 8px;
  background-color: rgba(40, 15, 65, 0.3);
}
.hr {
  margin: 0;
}
.checkbox_menu_wrapper {
  width: 150px;
  height: 250px;
  position: absolute;
  left: -16px;
  top: 24px;
  z-index: 2;
  border-radius: 8px;
  padding: 12px 12px 12px 0;
  background-color: rgb(63, 54, 90);
  overflow-y: auto;
}
.checkbox_menu_wrapper[data-theme="light"] {
  background-color: rgba(255, 255, 255, 1);
}
.checkbox_menu_wrapper[data-active="false"] {
  display: none;
}
.checkbox_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 32px;
  border-radius: 0 12px 12px 0;
}
.checkbox_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.checkbox_menu_wrapper[data-theme="light"] .checkbox_item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.checkbox_delete_icon {
  cursor: pointer;
}

.column_visible_menu_wrapper {
  width: 300px;

  background-color: rgb(63, 54, 90);
  position: absolute;
  top: 32px;
  z-index: 10;
  border-radius: 4px;
  padding: 12px;
}
.column_visible_menu_wrapper[data-theme="light"] {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.column_visible_menu_wrapper[data-active="false"] {
  display: none;
}
.columns_items {
  max-height: 250px;
  overflow: auto;
}
.column_visible_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column_visible_buttons span {
  cursor: pointer;
}

.column_visible_input input {
  width: 100%;
  outline: none;
  border: none;
}
.progress_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
