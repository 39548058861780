.container {
  width: 1080px;
  margin: 1em 0;
}
.header_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.select_menu_label {
  margin-right: 1em;
  white-space: nowrap;
}
.orig_dest_wrapper {
  display: flex;
  justify-content: space-between;
}
.origin,
.destination {
  display: flex;
  align-items: center;
}
.market_type_stay_duration {
  margin-top: 2em;
  display: flex;
}
.market_type {
  display: flex;
  align-items: center;
  margin-right: 1em;
}
.stay_duration {
  display: flex;
  align-items: center;
}
.currency {
  display: flex;
  align-items: center;
  margin-top: 2em;
}
.offset_wrapper {
  display: flex;
  margin-top: 2em;
}
.offset {
  display: flex;
  align-items: center;
  margin-right: 1em;
}
.website_wrapper {
  display: flex;
  justify-content: space-between;
}
.select_menu_wrapper {
  display: flex;
  align-items: center;
}
.website_save_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ec4252;
}
.buttons_wrapper {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 1em; */
}
.save_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ec4252;
}
.cancel_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid 1px var(--border-color);
  margin-right: 1em;
}
