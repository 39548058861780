.container {
  /* max-width: 1080px; */
  width: 100%;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
}
.head_buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit_filter_text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.edit_filter_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.clear_button {
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.24);
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ec4252;
  cursor: pointer;
}
.clear_button[data-theme="light"] {
  border: solid 1px rgba(28, 28, 37, 0.2);
  background-color: transparent;
}
.tick_button_wrapper {
  width: 40px;
  height: 40px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
.tick_button_wrapper[data-theme="light"] {
  border: solid 1px rgba(28, 28, 37, 0.2);
  background-color: transparent;
}
.tick_button_wrapper img {
  width: 15px;
}
.filter_card {
  padding: 0 16px 16px 16px;
  position: relative;
  top: -8px;
}
.from_text {
  margin: 8px 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.from_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.to_text {
  margin: 24px 0px 8px 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.to_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 8px;
}
.filter_buttons_wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.competitors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.comp_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.main_competitor {
  flex: 1;
  width: 100px;
}
.other_competitor {
  flex: 2;
  width: 100px;
}
.main_competitor_text {
  width: 56px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.main_competitor_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.other_competitor_text {
  width: 56px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.other_competitor_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.change_from_to_arrow_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  right: 50%;
  top: calc(50% - 9px);
}
.card_wrapper {
  /* width: 349px; */
  width: 100%;
}
.pos_comp_card_wrapper {
  width: 50%;
}
@media only screen and (max-width: 1280px) {
  .body {
    flex-wrap: wrap;
  }
  .card_wrapper {
    width: 100%;
  }
  .pos_comp_card_wrapper {
    width: 100%;
  }
  .competitors {
    flex-wrap: wrap;
  }
}
