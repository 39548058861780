.meaningful_element {
  margin-top: 8px;
}
.carrier {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.content {
  border-top: 1px solid var(--border-color);
  padding: 16px 32px 8px 32px;
}
.card {
  height: 58px;
  display: inline-flex;
  align-items: center;
  padding: 8px;
  margin: 0 4px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  background-color: hsla(0, 0%, 100%, 0.03);
}
.card_icon {
  width: 40px;
  height: 40px;
  border-radius: 16px;
  padding: 8px;
  margin-right: 8px;
}
.card_icon img {
  width: 24px;
  height: 24px;
}

.pos,
.neg {
  margin: 0;
}
.pos[data-active="false"] {
  display: none;
}
.neg[data-active="false"] {
  display: none;
}
.hr {
  display: inline-block;
  border-top: 1px solid var(--border-color);
  width: 100%;
  margin: 8px 0 4px 0;
}
.hr[data-active="false"] {
  display: none;
}
