.card_header {
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 20px;
}
.button_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  padding: 2px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.button {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
}
.button[data-active="true"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
