.container {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  border-radius: 15px 50px 15px 15px;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border: 1px solid rgba(0, 0, 7, 0.05);
}
.container[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.4);
}
.icon_wrapper {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 24px;
  background-color: #00ad92;
  border-radius: 50%;
}
.icon_wrapper img {
  width: 31px;
  height: 31px;
}
.title_content_wrapper span {
  width: 193px;
  height: 21px;
  font-family: var(--font-family-light);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.title_content_wrapper[data-theme="light"] span {
  color: var(--text-color-secondary-light);
}
.title_content_wrapper p {
  width: 350px;
  height: 26px;
  margin: 8px 0 0;

  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
