.header {
  width: 100%;
  height: 48px;
  margin-bottom: 8px;
}
.wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.year {
  width: 34px;
  height: 17px;
  margin: 0 12px 0 0;
  font-family: "Fabriga";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.year[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.color_scale_wrapper {
  width: 1048px;
  display: flex;
  overflow-x: auto;
}
.color_scale {
  display: flex;
  align-items: center;
}
.item {
  width: 16px;
  height: 17px;
  font-family: "Fabriga";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 24px 0 12px;
}
