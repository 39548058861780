.custom_table_row {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.table_wrapper {
  display: flex;
  margin: 1rem 0;
}
.dep_time {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 36px;
  margin-right: 8px;
  padding: 4px;
  border-radius: 8px;
  background-color: rgb(17, 12, 32);
}
.dep_time[data-theme="light"] {
  background-color: #cbbbef;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.row {
  color: var(--text-color-primary);
}
.bar_wrapper {
  width: 60px;
  height: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 1em;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  border-radius: 12px;
  font-size: 11px;
  line-height: normal;
}
