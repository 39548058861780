.input {
  width: 100%;
  height: 40px;
  padding: 4px 8px;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  color: #fff;
}
.input[data-theme="light"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
  background-color: rgba(255, 255, 255, 0.1);
}
.input:focus {
  outline: solid 1px rgba(255, 255, 255, 0.1);
}
.input:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
