.container {
  width: fit-content;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 -9px 18px 0 rgba(11, 0, 28, 0.5);
  background-color: #383051;
}
.container[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.content {
  padding: 16px 8px;
  overflow-y: auto;
}
.text_wrapper {
  display: flex;
  width: fit-content;
}
.text_left {
  min-width: 150px;
  display: inline-block;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.text_left[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.text_right {
  white-space: nowrap;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.text_right[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.divider {
  width: 100%;
  display: inline-block;
  margin: 4px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.divider[data-active="true"] {
  display: none;
}
