.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 0 28px 16px;
}
.buttons_wrapper {
  display: flex;
  align-items: center;
}
.search_button {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 20px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.search_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.search_icon {
  width: 24px;
  height: 24px;
  opacity: 0.6;
}
.search_button input {
  width: 138px;
  background-color: transparent;
  outline: none;
  border: none;
  opacity: 0.6;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.search_button[data-theme="light"] input {
  color: var(--text-color-primary-light);
}
.share_volume_buttons {
  width: 190px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 2px;
}
.share_volume_buttons[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.share_volume_buttons .button {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.share_volume_buttons[data-theme="light"] .button {
  color: var(--text-color-primary-light);
}
.share_volume_buttons .button[data-active="false"] {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
  border: none;
}
.share_volume_buttons[data-theme="light"] .button[data-active="false"] {
  background-color: #fff;
  color: var(--text-color-secondary-light);
  border: none;
  border-radius: 16px;
}
.setting_button {
  width: 36px;
  height: 36px;
  margin-left: 14px;
  border-radius: 10px;
  cursor: pointer;
}
.open_menu_wrapper {
  position: relative;
}
.setting_button {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  cursor: pointer;
}
.open_menu_wrapper .open_menu[data-active="false"] {
  display: none;
}
.open_menu_wrapper .open_menu {
  width: 229px;
  height: 263px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  right: 0;
  padding: 8px;
  z-index: 2;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}

.select_options {
  width: 90px;
  min-width: 90px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
  margin: 0 0 0 32px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: #4a4266;
  cursor: pointer;
}
.select_options[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.select_options_selected_item {
  margin-left: 8px;
}
.select_options_icon {
  width: 24px;
  height: 24px;
}
.select_options span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.select_options .open_menu {
  width: 90px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
}
.select_options .open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.select_options .open_menu[data-active="false"] {
  display: none;
}
.select_options .open_menu_item {
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}
.select_options .open_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.select_options .open_menu_item[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}
.card_wrapper {
  width: 216px;
  height: 120px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
  padding: 20px;
}
.lorem_text_and_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.text {
  width: 120px;
  height: 24px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.button[data-active="false"] {
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
.table_wrapper {
  margin: 32px 0;
}
.range_slider_wrapper {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.range_slider {
  width: 882px;
}
.range_slider[data-active="true"] {
  visibility: hidden;
}
.selected_item_wrapper {
  max-width: 1080px;
  max-height: 400px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  overflow: auto;
}
.selected_item {
  width: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 8px;
  font-size: 12px;
  white-space: nowrap !important;
}
.selected_item[data-theme="light"] {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
