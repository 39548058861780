.container {
  width: 100%;
}
.background_container {
  background-image: url(../../../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.background_container[data-theme="light"] {
  background-image: url(../../../assets/bg.webp);
}
.kpi_button {
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 0;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);

  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ec4252;
  cursor: pointer;
}
.kpi_button[data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.buttons_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
}
.arrow_button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_button[data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.arrow {
  border: solid #ec4252;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow_right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow_left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  left: 2px;
}
.slider_wrapper {
  width: 100%;
  min-height: 244px;
  overflow: hidden;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.slider {
  display: flex;
  gap: 16px;
  position: relative;
  transition: 700ms ease-in-out;
  transition: 700ms ease-in-out;
  -moz-transition: 700ms ease-in-out; /* Firefox 4 */
  -webkit-transition: 700ms ease-in-out; /* Safari and Chrome */
  -o-transition: 700ms ease-in-out; /* Opera */
  -ms-transition: 700ms ease-in-out; /* Explorer 10 */
}
.item {
  cursor: grab;
}

.extend_and_download_buttons {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 20px 0;
}
.extend_back_button {
  width: 84px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  color: #ec4252;
  cursor: pointer;
}
.extend_back_button[data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.extend_title {
  width: 290px;
  height: 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.extend_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.extend_download_button {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  cursor: pointer;
}
