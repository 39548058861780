.container {
}
.menu_wrapper {
  width: 100px;
  height: 200px;
  padding: 12px;
  background-color: var(--bg-color);
  background-image: var(--bg-image);
  color: var(--text-color-primary);
}
.selection_item {
  display: flex;
  align-items: center;
  justify-content: left;
}
.selection_item label {
  margin: 0;
  margin-right: 12px;
}
