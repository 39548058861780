.container {
  width: 100%;
  display: flex;
  align-items: center;
  /* overflow-x: scroll; */
}
.container *::-webkit-scrollbar {
  display: none;
}
.flight_icon {
  width: 24px;
  height: 24px;
  margin-left: 18px !important;
  object-fit: contain;
  opacity: 0.6;
}
.flight_icon[data-theme="light"] {
  opacity: 1;
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.carriers_text {
  width: 56px;
  min-width: 56px;
  height: 17px;
  margin: 0px 0px 0px 8px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.carriers_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.added_items {
  display: flex;
  overflow-x: scroll;
}
.add_wrapper {
  display: flex;
}
.add_button {
  width: 64px;
  height: 32px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.add_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.plus_icon {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}
.add_button_text {
  margin: 0 0 0px 4px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.add_button_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu {
  width: 64px !important;
  height: 336px;
  display: block;
  margin: 8px 0 0 12px;
  padding: 16px 0;
  position: absolute;
  z-index: 1;
  top: 48px;
  overflow-y: scroll;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu_items {
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
}
.open_menu_items:hover {
  border-radius: 0 10px 10px 0;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.open_menu_items[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}
.border_color {
  height: 24px;
  margin-right: 24px;
  border-radius: 0 5px 5px 0;
  border: 2px solid;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
}
.open_menu_item_text {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fff;
}
.open_menu_item_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.add_item_icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  object-fit: contain;
  opacity: 0.6;
}
.add_item_text {
  margin-left: 8px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.add_item_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.rounded_icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid;
}
.rounded_icon span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
