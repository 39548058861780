/* styles.module.css */

/* Select Menu bileşeni ana konteynır */
.container {
  min-width: 120px;
  position: relative;
  z-index: 999;
}

/* Seçilen seçeneğin göründüğü alan */
.selectedOption {
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 10px;
  border: solid 1px var(--border-color);
  background-color: var(--select-menu-bg-color);
  font-size: 16px;
  cursor: pointer;
}

/* Error durumunda  */
.error {
  border: solid 1px var(--border-color-error);
}

/* Placeholder yazı rengi  */
.placeholder {
  color: var(--text-color-disabled);
}

/* Seçeneklerin göründüğü liste */
.optionsList {
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 var(--card-shadow-color);
  background-color: var(--select-menu-list-bg-color);
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 1;
}

/* Seçeneklerin listesi içindeki her bir seçenek */
.option {
  padding: 12px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* Seçeneklerin listesi içindeki her bir seçeneğin üzerine gelindiğinde */
.option:hover {
  background-color: var(--select-menu-list-hover-bg-color);
}

/* Seçeneklerin listesi içindeki bir seçeneğin seçilmiş durumu */
.option[data-active="true"] {
  background-color: var(--select-menu-list-hover-bg-color);
}

/* Seçeneklerin listesindeki bir seçeneğin disabled durumu */
.disabled {
  cursor: no-drop;
  color: var(--text-color-disabled);
}

/* Seçilmiş olan seçeneğin pozisyonu */
.selection {
  position: relative;
  left: -4px;
}

/* Aşağı/ yukarı ikonlarının görünümü */
.icon_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 4px;
}

/* Temizleme ikonu görünümü */
.clear {
  width: 18px;
  height: 18px;
  display: none;
}
.selectedOption:hover .clear {
  display: block;
}
