.atarev_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.atarev_container[data-active="false"] {
  width: auto;
  height: auto;
  overflow: auto;
}

