.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 0 0 1em 0;
}
.button {
  width: 96px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color-secondary);
  border: 1px solid var(--border-color);
  cursor: no-drop;
  background-color: var(--button-disabled-color);
}
.button[data-active="true"] {
  color: var(--text-color-primary);
  background-color: rgba(255, 255, 255, 0.1);
}
