.container {
  position: relative;
  padding: 8px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.primary {
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border-radius: 5px;
}
.primary[data-theme="light"] {
  box-shadow: 0 5px 13px 0 rgba(108, 73, 172, 0.2),
    0 -25px 30px 0 rgba(108, 73, 172, 0.03);
  background-color: #fff;
}
.secondary {
  backdrop-filter: blur(28px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
}
.secondary[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  box-shadow: 0 2px 2px 0 rgba(108, 73, 172, 0.02),
    0 6px 5px 0 rgba(108, 73, 172, 0.03), 0 12px 10px 0 rgba(108, 73, 172, 0.04),
    0 22px 18px 0 rgba(108, 73, 172, 0.04),
    0 41px 33px 0 rgba(108, 73, 172, 0.05),
    0 100px 80px 0 rgba(108, 73, 172, 0.07);
}
.tertiary {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
}
.border {
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  width: 100%;
  
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.children {
  position: relative;
  z-index: 2;
}
.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.only_icon[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
