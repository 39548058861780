.container {
  position: relative;
  padding-bottom: 80px;
  z-index: 5;
}
.rules_priority {
  position: fixed;
  z-index: 3;
  bottom: 2vh;
}
