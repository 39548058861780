.body {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;
}

.left_metrics {
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.middle {
  width: 100%;
  height: 470px;
}
.chart_wrapper {
  width: 100%;
  height: 332px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat_title {
  height: 12px;
  font-family: var(--font-family-light);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: 18px;
  margin: 8px 0 0 0;
}
.range_slider_wrapper {
  padding: 0 28px;
}
.right_metrics {
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.metrics_wrapper {
  height: 322px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom_metrics_wrapper {
  margin-top: 8px;
}
.metrics_card_title {
  height: 15px;
  margin-top: 8px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.metrics {
  display: flex;
  margin-left: 8px;
  margin-bottom: 28px;
}
.metrics div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.icon_wrapper[data-active="blue"] {
  background-color: rgba(20, 74, 255, 0.1);
}
.icon_wrapper[data-active="purple"] {
  background-color: rgba(156, 0, 237, 0.1);
}
.icon_wrapper[data-active="green"] {
  background-color: rgba(0, 173, 146, 0.1);
}
.icon_wrapper[data-active="red"] {
  background-color: rgba(236, 66, 82, 0.1);
}

.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.title {
  font-family: var(--font-family-light);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.value {
  height: 24px;
  display: flex;
  align-items: flex-end;
  margin: 1px 0 0;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
