.container {
  background-color: var(--bg-color);
  background-image: var(--bg-image);
  padding: 12px;
  color: var(--text-color-primary);
}
.content {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
}
.select_col {
  min-width: 128px;
}
.filter_remove_icon {
  cursor: pointer;
}
.filter_remove_icon:hover {
  color: var(--text-color-primary);
}
.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
