.carriers_wrapper {
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.header_button_wrapper {
  width: 201px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}
.header_button_wrapper[data-theme="light"] {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.button {
  width: 32px;
  height: 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.button[data-theme="light"] {
  color: var(--text-color-secondary-light);
}

.button[data-active="true"] {
  color: #fff;
}
.button[data-active="true"][data-theme="light"] {
  color: var(--text-color-active-light);
}

.active_button {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.card_header_buttons {
  display: flex;
}
.dropdown_wrapper {
  width: 250px;
  position: relative;
  z-index: 2;
  top: -6px;
  margin: 0 16px;
}
.stats_wrapper {
  margin-top: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 8px;
}
.colon {
  display: flex;
  flex-direction: column;
}
.row {
  margin-left: 64px;
  display: flex;
  height: 64px;
}
.carrier_stat {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ec4252;
  border-radius: 50%;
  padding: 8px;
  margin: 8px;
}
.carrier_stat:nth-child(3) {
  margin-left: 111px;
}
.carrier_stat:nth-child(4) {
  margin-left: 111px;
}
.carrier_stat:nth-child(5) {
  margin-left: 112px;
}
.card {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 0 8px;
}
.card_content {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.statistics_wrapper {
  margin-top: 2em;
}

.rt_ow_switch_wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 1em;
  gap: 8px;
  margin-right: 1em;
}

.rt_ow_switch_wrapper span[data-select="true"] {
  cursor: no-drop;
}
.rt_ow_switch_wrapper span {
  width: 80px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color-secondary);
  border: 1px solid var(--border-color);
}
.rt_ow_switch_wrapper span[data-active="true"] {
  color: var(--text-color-primary);
  background-color: rgba(255, 255, 255, 0.1);
}
.custom_table_row {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.bar_wrapper {
  width: 60px;
  height: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 1em;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  border-radius: 12px;
  font-size: 11px;
  line-height: normal;
}