.custom_table_row {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card_header {
  padding: 24px;
  font-size: 20px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
}
.date_buttons_wrapper {
  margin-left: 1em;
  display: flex;
  align-items: center;
}
.datepicker_icon {
  cursor: pointer;
}
.datepicker_modal {
  position: absolute;
  z-index: 1;
  top: 64px;
  left: 0;
  border-radius: 10px;
  background-color: #383051;
}

.visible {
  display: none;
}
.today_button {
  width: 59px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid var(--border-color);
  margin: 0 1em;
  cursor: pointer;
  background-color: transparent;
}
.today_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.today_button[data-theme="light"] {
  background-color: transparent;
}
.today_button[data-active="true"][data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_left {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 12px 4px 4px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  margin-right: 8px;
}
.arrow_left[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_right {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px 12px 12px 4px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_right[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.date_text {
  margin-left: 1em;
  display: flex;
  align-items: center;
}
.table_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.progress_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
}
.bar_wrapper {
  width: 60px;
  height: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 1em;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  border-radius: 12px;
  font-size: 11px;
  line-height: normal;
}
