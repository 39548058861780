.tableHeaderCell {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 16px;
  line-height: 32px;
  vertical-align: middle;
  position: relative;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  cursor: grab;
}
.tableHeaderCell:active {
  cursor: grabbing;
}

.tableHeaderCell:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Üzerine gelindiğinde arka plan rengi değişir */
}
/* Sıralama ikonları için stil */
.headerCellIcons {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; /* İkon boyutu */
  color: var(--text-color-primary, #fff); /* İkon rengi, varsayılan beyaz */
  cursor: pointer;
}
