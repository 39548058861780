.container {
  width: 100%;
  overflow-x: auto;
}
.fields {
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  gap: 4px;
  color: var(--text-color-primary);
}

.field {
  width: 190px;
  min-width: 190px;
  display: inline-block;
  white-space: nowrap;
  color: var(--text-color-primary);
}
.field[data-active="true"] {
  width: 190px;
  min-width: 190px;
  display: inline-block;
  white-space: nowrap;
  color: var(--text-color-primary);
  visibility: hidden;
}

.field img {
  width: auto;
  height: 24px;
  padding-left: 6px;
}

.summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.carrier {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand_icon {
  width: 190px;
  min-width: 190px;
  display: flex;
  justify-content: flex-end;
}
.hr:not([size]) {
  height: 2px;
  color: var(--border-color);
}
