.fullscreen_button {
  display: none;
  width: 18px;
  position: absolute;
  right: 50px;
  top: 10px;
  cursor: pointer;
  color: #ffffff90;
}
.container[data-theme="light"] .fullscreen_button {
  color: #383874;
}
.container:hover .fullscreen_button {
  display: block;
}
.container[data-theme="light"][data-fullscreen="true"] {
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: cover;
}
.container[data-theme="dark"][data-fullscreen="true"] {
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: cover;
}
