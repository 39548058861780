.content {
  max-width: 250px;
  max-height: 380px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.title {
  font-size: 2.2em;
}
.description {
  margin: 1em 0;
  font-size: 1.3em;
}
