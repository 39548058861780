.container {
  width: 1080px;
  height: 128px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  background-color: rgba(255, 255, 255, 0.03);
  margin: 16px 0;
}

.content {
  display: flex;
  position: relative;
}

.left {
  width: 692px;
  height: 88px;
  display: inline-flex;
  padding: 8px 0 24px 40px;
  border-radius: 15px 0 0 0;
  background-color: rgba(255, 255, 255, 0.03);
  position: relative;
}
.filter_number {
  
  font-size: 28px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: absolute;
  left: 12px;
}
.card {
  width: 200px;
  height: 60px;
}
.card_top {
  display: flex;
  justify-content: space-between;
}
.card_title {
  height: 20px;
  display: flex;
  align-items: center;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.card_icon {
  width: 20px;
  height: 20px;
  opacity: 0.6;
}
.card_bottom {
  margin-top: 16px;
}
.params_wrapper {
  display: flex;
  align-items: flex-end;
}
.from_to_params {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_text {
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  align-items: flex-end;
}
.to {
  width: 16px;
  margin: 0 7px 0 5px;
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: flex-end;
}
.hr {
  width: 1px;
  height: 72px;
  display: inline-block;
  margin: 0 15px 0 12px;
  background-color: rgba(255, 255, 255, 0.1);
}
.right {
  width: 388px;
  height: 88px;
  display: inline-flex;
  padding: 8px 0 24px 16px;
  border-radius: 0 15px 0 0;
  background-color: rgba(255, 255, 255, 0.01);
}
.right .card {
  width: 140px;
  height: 60px;
}
.setting_button {
  width: 40px;
  height: 40px;
  margin-top: 24px;
  cursor: pointer;
}
.setting_button img {
  width: 40px;
  height: 40px;
}
.remove_filter {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.01);
  position: absolute;
  right: 28px;
  top: 75px;
  cursor: pointer;
}
.remove_filter img {
  width: 16px;
  height: 16px;
}
.card_footer {
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0 16px 24px;
}
.footer_title {
  height: 17px;
  margin: 0 8px 0 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.footer_content {
  height: 17px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
