.container {
  width: 100%;
  overflow-x: auto;
}
.fields {
  display: flex;
  padding: 0 1em;
  gap: 4px;
  color: var(--text-color-primary);
}
.fields[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.field {
  width: 190px;
  min-width: 190px;
  display: inline-block;
  white-space: nowrap;
  color: var(--text-color-primary);
}
.field[data-theme="light"] {
  color: var(--text-color-primary-light);
}

.field img {
  width: auto;
  height: 24px;
  padding-left: 6px;
}
.row {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 56px;
  padding: 0 1em;
  background-color: var(--bg-color-primary);
}

.carrier {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
