.container {
  margin: 16px 0;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
}
.head_buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit_filter_text {
  
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.clear_button {
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.24);
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ec4252;
  cursor: pointer;
}
.tick_button_wrapper {
  width: 40px;
  height: 40px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
.tick_button_wrapper img {
  width: 15px;
}
.filter_card {
  padding: 0 16px 16px 16px;
  position: relative;
  top: -8px;
}
.from_text {
  margin: 8px 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.to_text {
  margin: 24px 0px 8px 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.filter_buttons_wrapper {
  display: flex;
  align-items: center;
}
.competitors {
  display: flex;
  align-items: center;
}
.main_competitor {
  width: 120px;
}
.other_competitor {
  width: 240px;
}
.main_competitor_text {
  margin-right: 8px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.other_competitor_text {
  margin-right: 8px;
  margin-left: 28px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.change_from_to_arrow_wrapper {
  position: absolute;
  left: 42.7%;
  top: 43%;
  cursor: pointer;
}
