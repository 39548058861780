.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.button_wrapper {
  display: flex;
}
.tick_button {
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.tick_button img {
  width: 15px;
  height: 10px;
}
.tick_button[data-active="true"] img {
  width: 15px;
  height: 15px;
}
.clear_button {
  width: 130px;
  height: 40px;
  padding: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  color: #ec4252;
  cursor: pointer;
}
.clear_button[data-theme="light"] {
  border: 1px solid var(--text-color-disabled-light);
}
.body {
  display: flex;
  gap: 8px;
  overflow: hidden;
  overflow-x: auto;
}
.right_cards_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inbound_outbound_button_wrapper {
  position: relative;
  margin-top: 18px;
}
.active_line {
  position: absolute;
  height: 24px;
  border-left: 4px solid #ec4252;
  border-radius: 0 4px 4px 0;
  left: -8px;
  transition: ease-in-out 1s;
}
.inbound_outbound_button_wrapper p {
  width: 196px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 18px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  transition: ease-in-out 1s;
  cursor: pointer;
}
.inbound_outbound_button_wrapper p[data-active="false"] {
  color: #ec4252;
}
.travel_date_range_wrapper {
  margin: 8px 0 0 0px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
.travel_date_range_title {
  width: 224px;
  height: 40px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  align-items: center;
}
.travel_date_range_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.items_icon_wrapper {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.items_icon_wrapper img {
  width: 24px;
  height: 24px;
}
.travel_date_range_date_picker {
  margin: 20px 0 0 0;
  padding: 0 12px;
}
.travel_date_range_date_picker[data-active="true"] {
  border: 1px solid red;
  border-radius: 5px;
}
.hr {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}
.hr[data-theme="light"] {
  background-color: var(--text-color-primary-light);
}
.connection_button_wrapper {
  margin-top: 48px;
  display: flex;
}
.days_of_week_wrapper {
  margin-top: 16px;
  padding: 0 12px;
}
.days_of_week_wrapper .title {
  width: 120px;
  height: 24px;
  margin-bottom: 22px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.days_of_week_wrapper .title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.range_slider {
  padding: 0 32px;
  margin-top: 48px;
}
