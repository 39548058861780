.custom_date_picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
}
.date_now {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  margin: 0 32px;
}
.arrow_left,
.arrow_right {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: #fff;
}
.arrow_left[data-theme="light"],
.arrow_right[data-theme="light"] {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: #000;
}

.next_year,
.prev_year {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  cursor: pointer;
}
