.atarev_progress_line_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-background-primary);
  color: #5504d9;
  position: absolute;
  z-index: 20;
}
.atarev_progress_line_wrapper[data-theme="light"] {
  background-image: url(../../assets/bg.webp);
}
.atarev_progress_line_wrapper[data-active="false"] {
  display: none;
}
.atarev_progress_line_wrapper img {
  width: 400px;
  margin-bottom: 20px;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes example {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
