.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.wrapper {
  display: flex;
  gap: 8px;
}
@media only screen and (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
  }
}
