.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 18px;
}
.top span {
  width: 192px;
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.setting_button {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.setting_button img {
  width: 40px;
  height: 40px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 6px;
  overflow-x: auto;
}
.summary_card_container {
  width: 100%;
  min-width: 206.4px;
  height: 119px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 20px 16px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.03);
  overflow: hidden;
}
.summary_card_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary_card_title {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-secondary);
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.values {
  width: 156px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
}
.value {
  width: 156px;
  height: 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
