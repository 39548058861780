.container {
  width: 60%;
  margin-left: 16px;
}
.range_slider {
  display: flex;
  justify-content: center;
  padding: 0 30px;
}
.custom_table_row {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  color: #fff;
}
