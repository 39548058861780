.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.image_wrapper {
  width: calc(100% - 486px);
  height: 100vh;
  display: flex;
  justify-content: center;
}
.image_wrapper img {
  object-fit: cover;
}
.login_card {
  width: 486px;
  padding: 64px;
  border-radius: 40px 0 0 40px;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.03);
}
.login_card[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.logo_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.logo_wrapper img {
  width: 240px;
  height: 62px;
  object-fit: contain;
}
.login_welcome_text {
  width: 307px;
  height: 24px;
  margin: 170px 0 36px 0;
  font-family: var(--font-family-light);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.form_wraper {
  width: 100%;
}
.input {
  width: 358px;
  height: 40px;
  padding: 12px 35px 12px 16px;
  margin-bottom: 36px;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.input[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.input[data-active="true"] {
  width: 358px;
  height: 40px;
  padding: 12px 35px 12px 16px;
  margin-bottom: 36px;
  border-radius: 10px;
  border: solid 1px rgba(236, 66, 82, 0.6);
  color: rgba(236, 66, 82, 0.6);
  background-color: rgba(0, 0, 0, 0.1);
}
.input[data-active="true"]::placeholder {
  color: rgba(236, 66, 82, 0.6);
}
.input_wrapper {
  position: relative;
}
.password_visible {
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  top: 48px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
}
.password_visible[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.error_info {
  display: none;
}
.error_info[data-active="true"] {
  width: 24px;
  height: 24px;
  display: block;
  object-fit: contain;
  position: absolute;
  top: 48px;
  right: 12px;
  z-index: 2;
}
.login_button {
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  font-family: var(--font-family-medium);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
  transition: 300ms;
}
.login_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-primary-light);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.login_button[data-active="true"] {
  background-color: #ec4252;
}
.login_button[data-active="true"][data-theme="light"] {
  background-color: #ec4252;
  color: #fff;
}
.loader {
  width: 24px;
  height: 24px;
}
