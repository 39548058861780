.container {
  width: 1080px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  width: 897px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 50px 30px 30px 50px;
  object-fit: contain;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.1);
}
.card[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.active_deactive_button_wrapper {
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.active_deactive_button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.button {
  width: 95px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
}
.button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.button[data-active="true"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.1);
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 4px;
}
.dot[data-active="true"] {
  background-color: #00ad92;
}
.step_wrapper {
  display: flex;
}
.step_wrapper a {
  text-decoration: none;
}
.steps {
  cursor: pointer;
}
.step_name {
  width: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px 0 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  transition: 500ms ease-in-out;
}
.step_name[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.step_name[data-active="true"] {
  color: white;
}
.step_name[data-active="true"][data-theme="light"] {
  color: var(--text-color-primary-light);
}
.step {
  width: 152px;
  display: flex;
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  margin: 12px 4px 0 0;
  border-radius: 1px;
  transition: 500ms ease-in-out;
}
.step[data-theme="light"] {
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
}
.step[data-active="true"] {
  border-bottom: 4px solid #ec4252;
}
.next_button_wrapper {
  width: 78px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.next_button_wrapper[data-theme="light"] {
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
}
.next_button_wrapper[data-active="true"] {
  background-color: #ec4252;
}
.next_button_icon {
  width: 12px !important;
  height: 12px !important;
  color: inherit;
  margin-left: 4px;
  position: relative;
  top: 0.5px;
}
