.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
}
.not_card_text {
  height: 24px;
  display: flex;
  align-items: center;
  margin: 8px 8px 0 8px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.not_card_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.card_wrapper {
  height: 56px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 8px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-right: 8px;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.card_text {
  height: 25px;
  display: flex;
  align-items: center;
  font-family: var(--font-family-medium);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
