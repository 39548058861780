.top {
  display: flex;
  justify-content: space-between;
  padding: 18px;
}
.top span {
  width: 192px;
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.buttons_wrapper {
  display: flex;
  align-items: center;
}
.clear_button {
  width: 130px;
  height: 40px;
  padding: 11px 47px;
  border-radius: 10px;
  border: solid 1px var(--border-color);
  margin-right: 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ec4252;
}
.tick_button_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--border-color);
  background-color: rgba(255, 255, 255, 0.1);
}
.tick_button_wrapper img {
  width: 15px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  gap: 8px;
  overflow-x: auto;
}
.date_picker_wrapper {
  height: 284px;
  max-height: 284px;
}
.card_items {
  padding: 0 8px;
}
.card_in_buttons_wrapper {
  margin-top: 8px;
}
.card_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.card_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 12px 4px;
}
.card_title_wrapper .card_title {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.hr {
  width: 362px;
  height: 1px;
  margin-top: 32px;
  background-color: var(--border-color);
}
.repeated_days {
  width: 120px;
  height: 24px;
  margin: 24px 0 38px 0;
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.extra_margin {
  margin-top: 30px;
}
