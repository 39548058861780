.calendar_navigation_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.arrow_left {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 4px 4px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_left[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}

.arrow_right {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 12px 12px 4px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_right[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.calendar_wrapper {
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
}
.calendar_days {
  width: 100%;
  min-width: 140px;
  height: 24px;
  margin: 0 2px;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.calendar_days[data-theme="light"] {
  color: var(--text-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.calendar_days[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.calendar_days[data-active="true"][data-theme="light"] {
  color: var(--text-color-primary-light);
  background-color: var(--button-bg-color-primary-light);
}
.calendar_days[data-holiday="true"] {
  border: 1px solid rgba(85, 4, 217, 0.6);
}
.calendar_days[data-theme="light"][data-holiday="true"] {
  border: 1px solid rgba(85, 4, 217, 0.3);
}
.calendar_days img {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 4px;
}
.custom_table_row {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}
.row {
  color: var(--text-color-primary);
}
.bar_wrapper {
  width: 60px;
  height: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 1em;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  border-radius: 12px;
  font-size: 11px;
  line-height: normal;
}
