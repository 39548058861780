.container {
  width: 500px;
  flex-grow: 1;
}
.carriers_wrapper {
  display: flex;
  flex-grow: 1;
  margin-bottom: 8px;
}
.setting_button {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.range_wrapper {
  padding: 0 30px;
}
.charts_wrapper {
  padding: 0 30px;
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_wrapper {
  position: relative;
}
.open_menu {
  width: 229px;
  /* height: 271px; */
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  right: 0;
  padding: 8px;
  z-index: 2;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.top {
  width: 216px;
  height: 124px;
  padding: 24px 20px 20px 20px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
}
.top[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.top_text_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.open_menu_view_text {
  width: 120px;
  height: 24px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.open_menu_view_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.view_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.view_icon[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.top_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.open_menu_buttons[data-active="true"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
}
.open_menu_buttons[data-active="true"][data-theme="light"] {
  background-color: var(--button-bg-color-active-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.open_menu_buttons[data-active="false"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  font-family: var(--font-family-medium);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.24);
}
.open_menu_buttons[data-active="false"][data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.bottom {
  width: 216px;
  height: 124px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
}
