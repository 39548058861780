.contanier {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  position: relative;
  z-index: 11;
}
.dropdown_wrapper {
  width: 100%;
}
.button_wrapper {
  width: 400px;
  height: 64px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.setting_button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: 10px;
  background-color: #ec4252;
  color: #fff;
}
.menu_wrapper {
  width: 100%;
  height: 100vh;
  padding: 12px;
  overflow-y: auto;
}
.menu_title {
  font-size: 18px;
  text-align: center;
  color: var(--text-color-primary);
}
