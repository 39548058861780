@font-face {
  font-family: "Fabriga-Light";
  src: url("./FabrigaLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fabriga";
  src: url("./Fabriga-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fabriga-Medium";
  src: url("./Fabriga-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fabriga-Bold";
  src: url("./Fabriga-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
