.container {
  width: 100%;
  min-height: 49px !important;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  margin: 4px 0;
  overflow: hidden;
  color: var(--text-color-primary);
}
.title_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.expand_panel {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px;
  padding-left: 32px;
}
.expand_panel[data-active="false"] {
  display: none;
}
label {
  position: relative;
  margin-left: 12px;
}
.selected_count {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -20px;
  position: absolute;
  font-size: 11px;
  background-color: #5504d9;
  border-radius: 50%;
  padding-top: 2px;
}
