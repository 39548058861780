.container {
  width: 280px;
  min-width: 280px;
  position: relative;
  overflow: hidden;
  z-index: 5;
  transition: ease-in-out 700ms;
}
.container[data-active="true"] {
  width: 72px;
  min-width: 72px;
  margin-right: 40px;
}
.menu_content {
  width: 280px;
  min-width: 280px;
  height: 100vh;
  padding: 48px 0 16px 0;
  border-radius: 0 15px 15px 0;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  backdrop-filter: blur(5px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  transition: ease-in-out 700ms;
}
.menu_content[data-active="true"] {
  width: 72px;
  min-width: 72px;
  overflow: visible;
}
.menu_content[data-theme="dark"] {
  background-color: rgba(255, 255, 255, 0.03);
}
.menu_content[data-theme="light"] {
  box-shadow: 0 2px 2px 0 rgba(108, 73, 172, 0.02),
    0 6px 5px 0 rgba(108, 73, 172, 0.03), 0 12px 10px 0 rgba(108, 73, 172, 0.04),
    0 22px 18px 0 rgba(108, 73, 172, 0.04),
    0 41px 33px 0 rgba(108, 73, 172, 0.05),
    0 100px 80px 0 rgba(108, 73, 172, 0.07);
  background-color: var(--bg-color-primary-light);
}
.logo_wrapper {
  margin-bottom: 54px;
  margin-left: 24px;
  width: 132px;
  height: 34px;
}
.logo_wrapper img {
  width: 132px;
  height: 34px;
  transition: ease-in-out 700ms;
}
.logo_wrapper[data-active="true"] img {
  width: 25.9px;
  height: 26.9px;
}

.route {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.route_wrapper {
  min-height: calc(100% - 250px);
}

.route_wrapper a {
  text-decoration: none !important;
  cursor: pointer;
}

.route_icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 24px;
}
.route[data-theme="light"] .route_icon {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.route[data-active="true"] .route_icon {
  filter: invert(57%) sepia(44%) saturate(2206%) hue-rotate(335deg)
    brightness(105%) contrast(125%);
}
.route span {
  height: 24px;
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  transition: ease-in-out 700ms;
}
.route span[data-theme="dark"] {
  color: rgba(255, 255, 255, 0.6);
}
.route span[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.hr[data-active="true"] {
  position: absolute;
  height: 24px;
  border-left: 4px solid #ec4252;
  border-radius: 0 4px 4px 0;
}
.route span[data-active="true"] {
  margin-left: 48px;
}
.route[data-active="true"] span {
  color: #ec4252;
}

.user_wrapper {
  width: 232px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-left: 24px;
  margin-top: 16px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  transition: ease-in-out 700ms;
  cursor: pointer;
  position: relative;
}
.user_wrapper[data-theme="dark"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.user_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.user_wrapper[data-active="true"] {
  width: 48px;
  height: 48px;
  margin-left: 12px;
  padding: 8px;
}
.user_info_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}
.user_info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  transition: ease-in-out 700ms;
}
.user_wrapper[data-active="true"] .user_info {
  margin-left: 24px;
}
.user_name {
  width: 82px;
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  white-space: nowrap;
  max-width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}
.user_name[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.user_msd {
  height: 14px;
  opacity: 0.5;
  color: var(--text-color-primary);
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
}
.user_msd[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 66px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}
.arrow[data-theme="light"] {
  border: solid var(--text-color-primary-light);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 66px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}
.menu_open_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu_open_button_wrapper .menu_open_button {
  width: 40px;
  height: 40px;
  margin: 24px 24px 0 0;
  object-fit: contain;
  cursor: pointer;
  transition: ease-in-out 700ms;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
}
.menu_open_button_wrapper .menu_open_button[data-theme="light"] {
  color: var(--text-color-primary-light);
  background-color: var(--button-bg-color-primary-light);
}
.menu_open_button_wrapper[data-active="true"] .menu_open_button {
  transform: rotate(180deg);
  margin: 24px 15px 0 0;
}
.open_menu {
  width: 232px;
  position: absolute;
  padding: 16px 12px 20px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 rgba(11, 0, 28, 0.22);
  background-color: #383051;
  left: 0;
  top: -170px;
  z-index: 10;
}
.open_menu[data-theme="light"] {
  background-color: rgba(255, 255, 255, 1);
}
.open_menu[data-active="false"] {
  display: none;
}
.user_msd_lfa_switch_wrapper {
  width: 208px;
  height: 36px;
  padding: 2px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_msd_lfa_switch_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.user_msd_lfa_switch {
  width: 102px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.user_msd_lfa_switch[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.user_msd_lfa_switch[data-active="true"] {
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.user_msd_lfa_switch[data-active="true"][data-theme="light"] {
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: white;
  color: var(--text-color-primary-light);
}
.user_logout_wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}
.user_logout_wrapper[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.user_logout_wrapper .logout_icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
}
.user_logout_wrapper .logout_icon[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.user_logout_wrapper span {
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.disabled {
  cursor: no-drop;
}
.disabled[data-theme="dark"] {
  color: rgba(255, 255, 255, 0.2) !important;
}
.disabled[data-theme="light"] {
  color: rgba(0, 0, 0, 0.2) !important;
}
.theme_button_wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 12px;
  border-radius: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
  padding: 2px;
}
.theme_button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-primary-light);
}
.dark_button {
  width: 50%;
  height: 32px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.light_button {
  width: 50%;
  height: 32px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.light_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark_button[data-active="true"][data-theme="light"] {
  background-color: #fff;
}
.light_button[data-active="true"][data-theme="light"] {
  background-color: #fff;
}
.accordion {
  background-color: transparent !important;
  box-shadow: none !important;
  color: var(--text-color-primary) !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.accordion:last-child {
  border-bottom: solid 1px transparent;
}
.accordion[data-theme="light"] {
  color: var(--text-color-primary-light) !important;
}
.accordion_details_wrapper {
  position: relative;
}
.accordion_details {
  padding: 0 !important;
  overflow-x: hidden;
}
.icon {
  width: 24px !important;
  height: 24px !important;
  color: #fff !important;
}
.icon[data-theme="light"] {
  color: var(--text-color-primary-light) !important;
}
@media only screen and (max-width: 1024px) {
  .menu_open_button_wrapper {
    display: none;
  }
  .menu_content {
    padding: 48px 0 24px 0;
  }
}
