.container {
  width: 1080px;
}
.header_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.select_menu_label {
  margin-right: 1em;
  white-space: nowrap;
}
.orig_dest_wrapper {
  display: flex;
  justify-content: space-between;
}
.origin,
.destination {
  display: flex;
  align-items: center;
}
.cps_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}
.compatitors_wrapper {
  display: flex;
  align-items: center;
  /* margin-right: 1em; */
}
.dropdown_wrapper {
  max-width: 210px;
  min-width: 210px;
}
.buttons_wrapper {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 1em; */
}
.save_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ec4252;
  cursor: pointer;
}
.save_button[data-disabled="true"] {
  background-color: var(--bt-disabled-color);
  border: solid 1px var(--border-color);
}
.cancel_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid 1px var(--border-color);
  margin-right: 1em;
  cursor: pointer;
}
