.container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
}
.title {
  width: 96px;
  display: inline-block;
}
.description {
  margin-left: 8px;
}
.children {
  color: var(--text-color-primary);
}
.children[data-theme="light"] {
  color: var(--text-color-primary-light);
}
