.container {
  max-height: 400px;
  overflow-y: auto;
}
.content {
  padding: 12px;
  font-size: 14px;
}
.key {
  display: inline-block;
  width: 120px;
  text-transform: capitalize;
}
.value {
  margin-left: 8px;
}
