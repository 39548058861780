.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px 32px 8px;
}
.export_button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  cursor: pointer;
}
.export_button img {
  width: 24px;
  height: 24px;
}
.header_button_wrapper {
  width: 201px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}
.header_button_wrapper[data-theme="light"] {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.buttons_wrapper {
  display: flex;
}

.button {
  width: 32px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.button[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.button[data-active="true"] {
  color: #fff;
}
.button[data-active="true"][data-theme="light"] {
  color: var(--text-color-active-light);
}
.active_button {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.col_arrow_wrapper {
  display: flex;
  align-items: center;
  height: 48px;
}
.col_arrow {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
.col_arrow img {
  width: 9px;
  height: 11px;
}

.card_info_title {
  min-width: 120px;
  display: inline-block;
}

.progres {
  position: absolute;
  right: 50%;
  bottom: 50%;
  color: #fff;
}
.card_body {
  position: relative;
}
.fullscreen_button_wrapper {
  display: flex;
  justify-content: flex-end;
}
.fullscreen_button {
  cursor: pointer;
}
