.container {
  width: 1080px;
}
.card {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  padding: 8px;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px;
}
.card_title {
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.card_description {
  width: 214px;
  height: 15px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
}
.card_description[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.table_head {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 8px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.table_head[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.th_priority {
  width: 67px;
}
.th_id {
  width: 82px;
}
.th_rule_name {
  width: 167px;
}
.th_cabin {
  width: 60px;
}
.th_city_pair {
  width: 99px;
}
.th_eff_dates {
  width: 182px;
}
.th_travel_dates {
  width: 161px;
}
.th_auto {
  width: 56px;
}
.th_owner {
  width: 64px;
}
.th_active {
  width: 64px;
}

.table_body {
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.td_priority {
  width: 67px;
  width: 32px;
  height: 32px;
  margin-right: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}
.td_priority[data-theme="light"] {
}
.td_id {
  width: 82px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.td_rule_name {
  width: 167px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.td_cabin {
  width: 60px;
}
.td_city_pair {
  width: 99px;
}
.td_eff_dates {
  width: 182px;
}
.td_travel_dates {
  width: 161px;
}
.td_auto {
  width: 56px;
}
.td_owner {
  width: 64px;
}
.td_active {
  width: 64px;
}
.td_rule_info {
  width: 64px;
  margin-left: 8px;
  position: relative;
}
.td_active_dot {
  width: 8px;
  height: 8px;
  display: inline;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
}
.active_dot[data-active="true"] {
  background-color: #00ad92;
}
.save_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
}
.save_button {
  width: 100px;
  text-align: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #ec4252;
}
.card_info_title {
  min-width: 120px;
  display: inline-block;
}
.card_info_des {
  margin-left: 4px;
}
.strategy_card {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 12px;
  padding: 8px 0;
}
.strategy_card p {
  margin: 0;
}
