.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.card_header {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  padding: 0 8px;
}
.card_body {
  padding: 1em 8px;
}
.card_body_rules {
  padding: 1em 8px;
  width: 100%;
}
.rules_creator_body {
  display: flex;
  gap: 8px;
}
.rule_name {
  display: block;
  margin-bottom: 1em;
}
.rules_creator_description {
  display: flex;
  gap: 1em;
  border-left: 1px solid var(--border-color);
  padding-left: 2em;
  margin-left: 1em;
  height: 156px !important;
}
.rules_creator_description textarea {
  width: 100%;
  height: 100px;
  background-color: transparent;
  border-radius: 12px;
  padding: 8px;
  border-color: var(--border-color);
  color: var(--text-color-primary);
  margin: 1em 0;
}
.description {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.hr {
  margin: 2em 0;
}
.rules_criteria_body {
  display: flex;
  gap: 8px;
}
.left,
.right {
  width: 100%;
  display: flex;
  gap: 8px;
}
.left_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.rules_criteria_footer {
  margin: 8px 0;
  display: flex;
  gap: 8px;
}
.dtd_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.events_wrapper {
  display: flex;
  gap: 8px;
}
.card_wrapper {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}
.table_header {
  padding: 8px 1em;
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
}
.table_field {
  display: inline-flex;
  width: 200px;
  gap: 8px;
  margin-left: 8px;
}
.table_field:first-child,
.table_field:last-child {
  display: inline-flex;
  width: 40px;
}
.table_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.table_tr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 8px 1em;
}
.table_td {
  display: inline-flex;
  width: 200px;
  gap: 8px;
  margin-left: 8px;
}
.table_td:first-child {
  display: inline-flex;
  width: 40px;
}

.card_body_test {
  display: flex;
}
.setting_button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: 10px;
  background-color: #ec4252;
  color: #fff;
}
.menu_wrapper {
  width: 100%;
  height: 100vh;
  padding: 12px;
  overflow-y: auto;
}
.menu_title {
  font-size: 18px;
  text-align: center;
  color: var(--text-color-primary);
}
.selected_categories_wrapper {
  display: flex;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
}
.selected_categories {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
