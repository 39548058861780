.container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 4px;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.container *::-webkit-scrollbar {
  display: none;
}
.container * {
  scrollbar-width: none;
}
.container[data-active="true"] {
  background-color: rgba(100, 100, 100, 0.1);
  cursor: no-drop;
}
.container[data-theme="light"] {
  background-color: var(--dropdown-bg-color-primary-light);
}
.container[data-active="true"][data-theme="light"] {
  background-color: var(--dropdown-bg-color-disabled-light);
}
.required {
  border: solid 1px red;
}
.dropdown_input {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}
.item_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  padding: 0px 8px 0 0;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  background-color: rgba(255, 255, 255, 0.1);
}
.item_wrapper[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.dropdown_item_border {
  height: 24px;
  margin-right: 8px;
  border-radius: 0 5px 5px 0;
  border: 2px solid;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
}
.item_text {
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.item_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.close_icon {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
}
.close_icon[data-active="true"] {
  cursor: no-drop;
}
.arrow_icon {
  width: 24px;
  height: 24px;
}
.container[data-active="true"] .arrow_icon {
  cursor: no-drop;
}
.onClick {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.onClick[data-active="true"] {
  cursor: no-drop;
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu {
  min-width: 204px;
  max-height: 240px;
  display: block;
  position: absolute;
  object-fit: contain;
  top: 52px;
  left: -2px;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  z-index: 2;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.left_align {
  right: -2px !important;
  left: auto !important;
}

.right_align {
  left: -2px !important;
  right: auto !important;
}
.open_menu_scroll::-webkit-scrollbar {
  display: inline-block !important;
}
.open_menu_scroll {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: 2px !important;
}

.open_menu_search_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px 12px 20px;
  height: 40px;
  border-radius: 10px 10px 0 0;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.open_menu_search_wrapper[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.open_menu_search_wrapper input {
  background-color: transparent;
  outline: none;
  border: none;
  color: inherit;
}
.search_icon {
  width: 24px;
  height: 24px;
}
.open_menu_items {
  height: 40px;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 0px;
  cursor: pointer;
}
.open_menu_items[data-selected="true"] {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
}
.open_menu_items[data-selected="true"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.1);
}
.open_menu_items[data-selected="true"]:hover {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
}
.open_menu_items[data-selected="true"][data-theme="light"]:hover {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.open_menu_items:hover {
  width: 90%;
  border-radius: 0 10px 10px 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.open_menu_items[data-theme="light"]:hover {
  width: 90%;
  border-radius: 0 10px 10px 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.open_menu_item_border {
  height: 24px;
  margin-right: 24px;
  border-radius: 0 5px 5px 0;
  border: 2px solid;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
}
.options_item {
  width: 100%;
}
.city_airport_icon_wrapper {
  width: 24px;
  height: 24px;
}
.city_airport_icon_wrapper img {
  width: 20px;
  height: 20px;
}
.city_airport_icon_wrapper[data-active="true"] img {
  filter: grayscale(1);
}
.airport_code_text {
  color: rgba(255, 255, 255, 0.3);
}

.airport_code_text[data-active="true"] {
  color: #fff;
}
.airport_code_text[data-active="true"][data-theme="light"] {
  color: var(--text-color-primary-light);
}
.airport_code_text[data-theme="light"] {
  color: var(--text-color-disabled-light);
}
