.calendar_card {
  flex-basis: calc(25% - 10px);
  flex-grow: 1;
  border-radius: 0 0 5px 5px;
  background-color: rgba(85, 4, 217, 0.1);
}
.calendar_month_name_wrapper {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.calendar_month_name_wrapper[data-theme="light"] {
  background-color: rgba(85, 4, 217, 0.05);
}
.calendar_month_name {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar_button {
  cursor: pointer;
}
.calendar_button[data-active="false"] {
  display: none;
}
.days_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--text-color-secondary);
}
.days_name[data-theme="light"] {
  background-color: #fff;
  color: var(--text-color-primary);
}
.days_name span {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Fabriga;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  overflow: hidden;
}
.day {
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(85, 4, 217, 0.1);
  font-family: Fabriga;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color-primary);
  position: relative;
}
.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  /* background-color: #ec4252; */
  position: absolute;
  top: 28px;
}
