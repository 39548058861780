.button {
  width: 200px;
  height: 40px;
  border-radius: 100px;
  background-color: #ff4b5c;
  outline: none;
  border: none;
  color: #fff;
  position: fixed;
  z-index: 1000;
  right: -156px;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 1em;
  transition: ease-in-out 300ms;
}
.button span {
  margin-left: 12px;
}

.button:hover {
  right: -24px;
}
.progress {
  width: 156px;
}
