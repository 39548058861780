.container {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
  position: relative;
  border-radius: 12px;
}
.container[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.open_menu_wrapper {
  display: none;
  width: 100%;
  min-width: 250px;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #3f365a;
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 1;
  border-radius: 8px;
  padding: 12px 0;
}
.open_menu_wrapper[data-active="true"] {
  display: block;
}
.open_menu_wrapper[data-active="true"][data-theme="light"] {
  background-color: var(--bg-color-white);
}
/* .open_menu_wrapper[data-disabled="true"] {
    background-color: rgba(255, 255, 255, 0.2);
} */

.selected_option_wrapper[data-disabled="true"] {
  cursor: no-drop;
  /* background-color: rgba(255, 255, 255, 0.2);  */
}
.selected_option_wrapper {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
}
.selected_option {
  width: 104px;
  max-width: 104px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option {
  width: 90%;
  height: 32px;
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 12px 0px 12px;
  font-size: 14px;
}
.option:hover {
  background-color: rgba(32, 27, 54, 0.7);
}
.option[data-theme="light"]:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
