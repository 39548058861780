.container {
  margin-bottom: 24px;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.add_button {
  width: 87px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
}
.add_button[data-theme="light"] {
  color: var(--text-color-primary-light);
  background-color: var(--button-bg-color-primary-light);
}
.add_button img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.bounce {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00ad92;
}
.bounce[data-active="false"] {
  background-color: rgba(255, 255, 255, 0.4);
}
.card_body {
  position: relative;
}
.fullscreen_button_wrapper {
  display: flex;
  justify-content: flex-end;
}
.fullscreen_button {
  cursor: pointer;
}

.menu_item {
  height: 40px !important;
  border-radius: 0px 10px 10px 0 !important;
  padding: 0 16px !important;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6) !important;
}
.menu_item[data-theme="light"] {
  color: var(--text-color-primary-light) !important;
}
.menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}
.menu_item[data-theme="light"]:hover {
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
