.container {
  width: 1080px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 15px;
  border: solid 2px rgba(255, 255, 255, 0.2);
  border-style: dashed;
  cursor: pointer;

  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
}
.plus_icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
