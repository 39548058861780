.close_button_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
}
.switch_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.switch_button {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
}
.switch_button[data-active="true"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.close_button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  background-color: hsla(0, 0%, 100%, 0.1);
}
.close_button_wrapper img {
  width: 24px;
  height: 24px;
}
