.container {
  display: flex;
  gap: 16px;
}
.left_menu {
  flex: 0;
}
.body {
  flex: 1;
  width: calc(100% - 320px);
  padding: 24px 16px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.children {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.side_drawer {
  display: none;
}
.filter_wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
  .left_menu {
    display: none;
  }
  .body {
    width: 100%;
  }
  .side_drawer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 1em 1em 0;
  }
  .side_drawer img {
    width: 200px;
    height: auto;
  }
}
