.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.image_wrapper {
  width: calc(100% - 974px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.image_wrapper img {
  width: 974px;
  height: 773.5px;
  object-fit: cover;
}
.user_card {
  width: 232px;
  min-width: 232px;
  height: 56px;
  display: flex;
  align-content: center;
  padding: 12px;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  position: relative;
  left: 64px;
  bottom: 40px;
  z-index: 2;
}
.user_card[data-theme="light"] {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: var(--button-bg-color-primary-light);
}
.user_card img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.user_name {
  width: 100%;
}
.avatar {
  width: 32px !important;
  height: 32px !important;
  margin-right: 8px;
}
.user_name .name {
  margin: 1px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
}
.user_name .airline_name {
  margin: 1px;
  opacity: 0.5;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}
.arrow[data-theme="light"] {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
}
.select_card {
  width: 51vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px 0 0 40px;
  padding: 64px;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.03);
  position: relative;
}
.select_card[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.2);
}
.user_and_logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_welcome_text {
  font-family: var(--font-family-medium);
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.open_menu {
  width: 232px;
  position: absolute;
  padding: 16px 12px 20px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 rgba(11, 0, 28, 0.22);
  background-color: #383051;
  left: 0;
  top: -173px;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.open_menu[data-active="false"] {
  display: none;
}
.user_msd_lfa_switch_wrapper {
  width: 208px;
  height: 36px;
  padding: 2px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_msd_lfa_switch_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.user_msd_lfa_switch {
  width: 102px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.user_msd_lfa_switch[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.user_msd_lfa_switch[data-active="true"] {
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.user_msd_lfa_switch[data-active="true"][data-theme="light"] {
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: var(--bg-color-white);
}
.user_logout_wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 8px;
  cursor: pointer;
}

.user_logout_wrapper span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.user_logout_wrapper[data-theme="light"] span {
  color: var(--text-color-secondary-light);
}
.logo {
  width: 240px;
  height: 62px;
  object-fit: contain;
}
.map_wrapper {
  width: 90%;
  z-index: -1;
  position: absolute;
  margin: 148px 0 47px 0;
}
.map_wrapper img {
  width: 100%;
  max-width: 846px;
}
.select_input_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social_media_icons_wrapper {
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.social_media_icons {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  object-fit: contain;
}
.select_input_card_wrapper {
  display: flex;
}
.select_input_card {
  height: 100px;
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.05);
}
.select_input_card[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.country_wrapper {
  width: 247px;
  padding: 0 24px;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}
.content_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.dropdown_wrapper {
  display: flex;
  align-items: center;
}
.dropdown {
  width: 84px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}
.arrow_down {
  padding: 2px;
  margin: 0 0 0 10px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.msd_lfa_button_wrapper {
  width: 52px;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.05);
}
.msd_lfa_button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.msd_lfa_button {
  width: 48px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.msd_lfa_button[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.msd_lfa_button[data-active="true"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.msd_lfa_button[data-active="true"][data-theme="light"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: var(--bg-color-white);
}
.explorer_result_wrapper {
  width: 72px;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ec4252;
  outline: none;
  border: none;
}
.explorer_result_wrapper[data-active="false"] {
  background-color: rgba(255, 255, 255, 0.2);
}
.explorer_result_wrapper[data-active="false"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.2);
}

.explorer_result_wrapper span {
  width: 51px;
  height: 34px;
  margin-bottom: 14px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.1px;
  color: rgba(255, 255, 255, 0.6);
}
.arrow_icon {
  width: 24px;
  height: 24px;
  margin: 0 8px;
  object-fit: contain;
}
.disabled[data-theme="light"] {
  color: rgba(0, 0, 0, 0.2) !important;
}
.theme_button_wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 12px;
  border-radius: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
  padding: 2px;
  cursor: pointer;
}
.theme_button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-primary-light);
}
.dark_button {
  width: 50%;
  height: 32px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.light_button {
  width: 50%;
  height: 32px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.light_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark_button[data-active="true"][data-theme="light"] {
  background-color: #fff;
}
.light_button[data-active="true"][data-theme="light"] {
  background-color: #fff;
}
.logout_icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
}
.logout_icon[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.revenue_suit_button {
  width: 72px;
  height: 100px;
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.05);
  margin-left: 8px;
  text-align: center;
  cursor: pointer;
}
.revenue_suit_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.revenue_suit_button[data-active="true"] .rs {
  background-color: rgba(255, 255, 255, 0.1);
}
.rs {
  width: 52px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.rs[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-secondary-light);
}
.revenue_suit_button[data-active="true"][data-theme="light"] .rs {
  background-color: var(--bg-color-white);
}
.revenue_suit_button[data-visible="false"] {
  display: none;
}
