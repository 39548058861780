.container {
  width: 100%;
  min-height: 660px;
}
.fullscreen_button_wrapper {
  display: flex;
  justify-content: flex-end;
}
.fullscreen_button {
  cursor: pointer;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  position: relative;
  z-index: 10;
}

.left_filter {
  display: flex;
  align-items: center;
}

.date_picker_wrapper {
  margin-right: 8px;
  position: relative;
}

.date_picker_modal {
  position: absolute;
  width: 300px;
  background-color: #383051;
  border-radius: 10px;
  top: 36px;
}

.date_picker_modal[data-active="false"] {
  display: none;
}

.date_picker_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.date_picker_icon[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}

.date_picker_icon img {
  width: 16px;
  height: 16px;
}

.card_header_select_options {
  width: 74px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  cursor: pointer;
}
.card_header_select_options[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.select_options {
  min-width: 74px;
  max-width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family-medium);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.open_menu {
  width: 74px;
  padding: 16px 6px 16px 0;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}

.open_menu_item {
  width: 66px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.open_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.open_menu_item[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}

.card_header_arrows {
  width: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
}

.card_header_arrows .arrow_left {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 4px 4px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_left[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_left_disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

.card_header_arrows .arrow_right {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 12px 12px 4px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.arrow_right[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_date_text {
  margin-left: 8px;
}

.header_button_wrapper {
  width: 201px;
  min-width: 201px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  margin-left: 24px;
}
.header_button_wrapper[data-theme="light"] {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.button {
  width: 32px;
  height: 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.button[data-theme="light"] {
  color: var(--text-color-secondary-light);
}

.button[data-active="true"] {
  color: #fff;
}
.button[data-active="true"][data-theme="light"] {
  color: var(--text-color-active-light);
}

.active_button {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}

.card_body {
  margin: 0;
}

.today_button {
  width: 59px;
  height: 24px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  font-family: var(--font-family-medium);
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  cursor: pointer;
}
.today_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.today_button[data-active="true"] {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.today_button[data-active="true"][data-theme="light"] {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.holiday_wrapper {
  display: none;
}
.holiday_wrapper[data-holiday="true"] {
  display: flex;
  border: 1px solid rgba(85, 4, 217, 0.6);
  border-radius: 8px;
  padding: 8px;
}
.holiday_wrapper img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.holiday_wrapper[data-theme="light"][data-holiday="true"] {
  display: flex;
  border: 1px solid rgba(85, 4, 217, 0.3);
  border-radius: 8px;
  padding: 8px;
}
.arrow_filter_wrapper {
  display: flex;
  margin-left: 1em;
}
.arrow_filter_wrapper[data-visible="false"] {
  display: none;
}
.filter_button {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  padding: 4px;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.filter_button[data-active="true"] {
  border: 1px solid #ec4252;
}
.filter_button img {
  width: 9px;
  /* height: 20px !important; */
}
.modal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.rt_ow_switch_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1em;
  gap: 8px;
}

.rt_ow_switch_wrapper span[data-select="true"] {
  cursor: no-drop;
}
.rt_ow_switch_wrapper span {
  width: 80px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color-secondary);
  border: 1px solid var(--border-color);
}
.rt_ow_switch_wrapper span[data-active="true"] {
  color: var(--text-color-primary);
  background-color: rgba(255, 255, 255, 0.1);
}
