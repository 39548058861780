.axis_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 12px;
  font-family: var(--font-family-light);
}
.chart_x_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
