.container {
  /* width: 1080px; */
  /* height: 832px; */
  margin-bottom: 24px;
}
.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 18px;
}
.card_title {
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_body {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  gap: 8px;
}
.in_card_wrapper {
  width: 30%;
  height: 493px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.in_card {
  padding: 8px;
}
.disabled {
  background-color: rgba(255, 255, 255, 0.2);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  /* width: 166px; */
  height: 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.datepicler_wrapper {
  width: 40%;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.24);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.datepicler_wrapper[data-theme="light"] {
  background-color: #fff;
}
.dropdown_wrapper {
  margin: 37px 0 10px 0;
}

.form_element {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  padding: 0 8px;
}
.input[data-theme="light"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary-light);
}
.flight_number {
  width: 295px !important;
  height: 40px;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  padding: 0 8px;
}
.arrow_right {
  width: 18px !important;
  height: 18px !important;
  margin: 0 4px;
}
.dow_wrapper {
  width: 100%;
  display: flex;
  margin-top: 16px;
}
.cabin_wrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.cabin {
  margin-right: 8px;
}
.divider {
  display: flex;
  justify-content: center;
  margin: 4px 0 24px 0;
}
.divider span {
  width: 362px;
  height: 1px !important;
  border: solid 1px rgba(255, 255, 255, 0.05) !important;
}
.divider[data-theme="light"] span {
  border: solid 1px rgba(0, 0, 0, 0.05) !important;
}
.dropdown_wrapper_in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error {
  border: 1px solid red;
}
.range_slider {
  padding: 20px;
}
.arrow_change {
  transform: rotate(90deg);
}
.forever_wrapper {
  display: flex;
  justify-content: center;
}
.buttons_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.forever_wrapper[data-theme="light"] .buttons_wrapper {
  background-color: var(--button-bg-color-primary-light);
}
.button_forever {
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
}
.button_forever[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.2);
}
.forever_wrapper[data-theme="light"] .button_forever[data-active="true"] {
  background-color: #fff;
}
.forever_text {
  font-size: 1.3em;
  position: relative;
  top: 2px;
  margin-left: 4px;
}
