.container {
  margin-bottom: 24px;
  position: relative;
  z-index: 2;
}
* input[type="number"]::-webkit-inner-spin-button,
* input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* input[type="number"] {
  -moz-appearance: textfield;
}
.card_header {
  padding: 20px 18px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_header[data-theme="light"] {
  color: var(--text-color-primary-light);
}

.body {
  width: 100%;
  display: flex;
  gap: 8px;
}
.card_wrapper {
  width: 50%;
}
.in_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
}
.in_card_header div {
  display: flex;
  align-items: center;
}
.add_button {
  width: 87px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-align: center;
  padding: 4px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 20px;
  cursor: pointer;
}
.add_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.in_card_body {
}
.table_head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 36px;
  height: 32px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.table_head[data-theme="light"] {
  background-color: rgba(85, 4, 217, 0.05);
  color: var(--text-color-primary-light);
}

.table_head_input,
.table_head_operator,
.table_head_value,
.table_head_category,
.table_head_action,
.table_head_set_avail,
.input_dropdown_wrapper,
.operator_dropdown_wrapper,
.value_dropdown_wrapper,
.category_dropdown_wrapper,
.action_dropdown_wrapper {
  min-width: 143px;
}

.td {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 4px 0;
  padding: 0 8px 0 16px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
}
.td[data-theme="light"] {
  background-color: var(--bg-color-white);
  box-shadow: 0 5px 13px 0 rgba(108, 73, 172, 0.2),
    0 -25px 30px 0 rgba(108, 73, 172, 0.03);
}
.td[data-active="true"] {
  border: 1px solid red;
}
.td:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.td_count {
  width: 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.value_dropdown_wrapper {
  display: flex;
  gap: 4px;
}
.value_dropdown_wrapper input {
  width: 80px;
  height: 30px;
  border-radius: 5px;
}
.value_dropdown_wrapper[data-theme="light"] input {
  background-color: rgba(56, 56, 116, 0.1) !important;
  border: none !important;
}

.cancel_icon_wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 12px;
}
.cancel_icon {
  width: 14px !important;
  height: 14px !important;
}
