.container {
  display: flex;
  gap: 16px;
}
.bottom_card {
  margin-top: 8px;
}
.card_container {
  padding: 8px 16px;
}
.title {
  width: 72px;
  height: 15px;
  margin: 0 12px 26px 0;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.bound_switch_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 25%;
  right: 25%;
  margin-top: 4px;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.switch_button {
  width: 93px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.switch_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-secondary-light);
}
.switch_button[data-active="true"] {
  width: 93px;
  height: 32px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}

.switch_button[data-active="true"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.1);
}
.gradient_line_wrapper {
  width: 100%;
  margin-top: 28px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.line {
  width: 100%;
  border-style: solid;
  border-radius: 20px;
  border-image-slice: 1;
  border-width: 2px;
}
.text {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  opacity: 0.6;

  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}

.slider_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 16px;
  transition: ease-in-out 300ms;
}
.slider_title {
  width: 131px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.prev_arrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.1);
}
.prev_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_left {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.arrow_left[data-theme="light"] {
  border: solid var(--text-color-primary-light);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.next_arrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 50% 50% 0;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.1);
}
.next_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_right {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}
.arrow_right[data-theme="light"] {
  border: solid var(--text-color-primary-light);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}
.slider_wrapper {
  position: relative;
  overflow: hidden;
}
.slider {
  width: 100%;
  height: 410px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: ease-in-out 300ms;
}
.items {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
}
.active_wrapper {
  display: flex;
  justify-content: center;
}
.active_slide {
  width: 18px;
  height: 4px;
  display: block;
  margin: 0 4px;
  border-radius: 1px;
  border: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: ease-in-out 1s;
}
.active_slide[data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.2);
}
.active_slide[data-active="true"] {
  background-color: #ec4252;
}
