.card_header {
  display: flex;
  justify-content: space-between;
  padding: 20px 12px 0 18px;
}
.card_body {
  overflow-x: auto;
}
.card_title {
  width: 128px;
  height: 24px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.header_button_wrapper {
  width: 240px;
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px var(--border-color);
  position: relative;
}
.button {
  width: 21px;
  height: 20px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color-secondary);
  cursor: pointer;
}

.active_button {
  width: 120px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.card_body_title_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 36px 0 0 390px;
}
.card_body_title_wrapper .title {
  min-width: 162px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.card_body_content_wrapper {
  margin-top: 6px;
}
.card_body_content {
  display: flex;
  margin-top: 2px;
}
.items_title_wrapper {
  min-width: 384px;
  width: 384px;
  height: 71px;
  display: flex;
  align-items: center;
  padding: 16px;
  margin-right: 8px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
.card_body_content_items_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.items_icon_wrapper {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-right: 16px;
}
.items_icon_wrapper img {
  width: 24px;
  height: 24px;
}
.items {
  min-width: 162px;
  width: 100%;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}
.items[data-active="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}
.items img {
  width: 32px;
  height: 32px;
}
