.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.button_wrapper {
  display: flex;
  cursor: pointer;
}
.tick_button {
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.tick_button img {
  width: 24px;
  height: 24px;
}
.in_card_wrapper {
  display: flex;
  gap: 8px;
  overflow: hidden;
  overflow-x: auto;
}
.travel_date_range_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 24px;
}
.travel_date_range_title {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
}
.travel_date_range_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.items_icon_wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.items_icon_wrapper img {
  width: 24px;
  height: 24px;
}
.text_wrapper {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 8px;
}
.date_text {
  margin: 0 8px;
}
.date_button {
  cursor: pointer;
}
.left_text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.right_text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
}
.hr {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 12px 0;
  color: #ec4252e6;
}
.hr[data-theme] {
  background-color: var(--text-color-primary-light);
}
.days_wrapper {
  display: flex;
}
