.container {
  width: 100%;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  background-color: rgba(255, 255, 255, 0.03);
}
.container[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  box-shadow: 0 2px 2px 0 rgba(108, 73, 172, 0.02),
    0 6px 5px 0 rgba(108, 73, 172, 0.03), 0 12px 10px 0 rgba(108, 73, 172, 0.04),
    0 22px 18px 0 rgba(108, 73, 172, 0.04),
    0 41px 33px 0 rgba(108, 73, 172, 0.05),
    0 100px 80px 0 rgba(108, 73, 172, 0.07);
}
.content {
  display: flex;
}

.left {
  width: 692px;
  height: 88px;
  display: inline-flex;
  padding: 8px 0 24px 24px;
  border-radius: 15px 0 0 0;
  background-color: rgba(255, 255, 255, 0.03);
}
.left[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.25);
}
.card {
  width: 200px;
  height: 60px;
}
.card_top {
  display: flex;
  justify-content: space-between;
}
.card_title {
  height: 20px;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.card_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.card_icon {
  width: 20px;
  height: 20px;
  opacity: 0.6;
}
.card_icon[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.card_bottom {
  margin-top: 16px;
}
.params_wrapper {
  display: flex;
}
.from_to_params {
  max-width: 200px;
  min-width: 56px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.to {
  width: 16px;
  height: 24px;
  margin: 0 7px 0 9px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
}
.to[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.hr {
  width: 1px;
  height: 72px;
  display: inline-block;
  margin: 0 15px 0 12px;
  background-color: rgba(255, 255, 255, 0.1);
}
.right {
  width: 388px;
  height: 88px;
  display: inline-flex;
  flex-grow: 1;
  padding: 8px 0 24px 16px;
  border-radius: 0 15px 0 0;
  background-color: rgba(255, 255, 255, 0.01);
}
.right .card {
  width: 140px;
  height: 60px;
}
.setting_button {
  width: 40px;
  height: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-right: 24px;
}
.setting_button img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-radius: 0 0 8px 16px;
}
.card_footer[data-theme="light"] {
  background-color: rgba(85, 4, 217, 0.05);
}
.footer_title {
  height: 17px;
  margin: 0 8px 0 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.footer_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.footer_content {
  height: 17px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.footer_content[data-theme="light"] {
  color: var(--text-color-primary-light);
}
@media only screen and (max-width: 1366px) {
  .left .card:nth-child(-n + 3),
  .left .hr:nth-child(-n + 4),
  .right .card:nth-child(-n + 2),
  .right .hr:nth-child(-n + 3) {
    display: none;
  }
  .container {
    width: 100%;
  }
  .left,
  .right {
    width: 50%;
    /* padding: 8px; */
  }
  .right {
    justify-content: space-between;
    padding-right: 12px;
  }
}
