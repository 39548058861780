.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 0 28px 16px;
}
.open_menu_wrapper {
  position: relative;
}
.setting_button {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  cursor: pointer;
}
.open_menu {
  display: none;
}
.open_menu[data-active="true"] {
  width: 232px;
  height: 264px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 5;
  right: 0;
  padding: 8px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}
.open_menu[data-active="true"][data-theme="light"] {
  background-color: var(--bg-color-white);
}
.card_wrapper {
  width: 216px;
  height: 120px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
  padding: 20px;
}
.card_wrapper[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.lorem_text_and_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.text {
  width: 120px;
  height: 24px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.icon[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
}
.button[data-theme="light"] {
  background-color: var(--button-bg-color-active-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-secondary-light);
}
.button[data-active="false"] {
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
.button[data-active="false"][data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: var(--text-color-secondary-light);
}
.chart_wrapper {
  height: 510px;
  padding: 0px;
  position: relative;
}
.chart_info {
  width: 181px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-light);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  padding: 8px;
  margin: 4px 0 2px 4px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  z-index: 1;
}
.chart_info[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.chart_info2 {
  width: 181px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  padding: 8px;
  margin: 4px 0 2px 4px;
  position: absolute;
  z-index: 1;
}
.chart_info2[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.top_left {
  top: 20px;
  left: 0;
}
.top_right {
  top: 20px;
  right: 0;
}
.bottom_left {
  bottom: 0;
  left: 0;
  margin-bottom: 12px;
}
.bottom_right {
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
}
.center_right {
  right: -70px;
  bottom: 50%;
  transform: rotate(90deg);
}
.top_center {
  top: 20px;
  left: 40%;
}
