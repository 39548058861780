@import url(../assets/fonts/main-font-face.css);

:root {
  /*
  colors definitions here, they should be used in other components will be used across application
  */
  --color-background-primary: rgb(17, 12, 32);
  --color-background-secondary: rgb(32, 27, 54);
  --color-background-dropdowns: rgb(63, 54, 90);
  --color-text-primary: rgb(255, 255, 255);
  --color-text-secondary: rgba(0, 0, 0, 0.6);
  --color-text-active: rgb(255, 75, 92);

  --text-font-size-base: 16px;
  --text-font-size-tiny: 10px;
  --text-font-size-small: 12px;
  --text-font-size-medium: 16px;
  --text-font-size-large: 20px;

  --bg-color-primary-dark: rgb(17, 12, 32);
  --bg-color-primary: rgb(17, 12, 32);
  --bg-color-white: rgba(255, 255, 255, 1);
  --bg-color-primary-light: rgba(255, 255, 255, 0.8);
  --bg-color-secondary-light: rgba(255, 255, 255, 0.4);
  --bg-color-third-light: rgba(0, 0, 0, 0.05);

  --button-bg-color-primary-light: rgba(85, 4, 217, 0.1);
  --button-bg-color-active-primary-light: rgba(56, 56, 116, 0.05);

  --menu-bg-color-primary-light: rgba(28, 28, 37, 0.05);

  --dropdown-bg-color-primary-light: rgba(241, 242, 246, 1);
  --dropdown-bg-color-disabled-light: rgba(241, 242, 246, 0.6);

  --text-color-primary-light: rgb(56, 56, 116);
  --text-color-secondary-light: rgba(56, 56, 116, 0.6);
  --text-color-active-light: rgb(236, 66, 82);
  --text-color-disabled-light: rgba(0, 0, 0, 0.2);

  --font-family-light: "Fabriga-Light";
  --font-family-normal: "Fabriga";
  --font-family-medium: "Fabriga-Medium";
  --font-family-bold: "Fabriga-Bold";

  --material-theme: rgb(63, 54, 90);
  --mui-text-color: rgb(255, 255, 255);

  --bg-color: rgb(255, 255, 255);
  --bg-image: url(../assets/background.png);

  --button-primary-color: rgba(255, 255, 255, 0.1);
  --button-active-color: rgb(236, 66, 82);
  --button-disabled-color: rgba(255, 255, 255, 0.2);

  --input-primary-color: rgba(0, 0, 0, 0.1);
  --input-primary-hover-color: rgba(255, 255, 255, 0.1);
  --input-primary-item-color: rgba(255, 255, 255, 0.1);
  --input-primary-acitve-color: rgba(255, 255, 255, 0.1);

  --border-color: rgba(255, 255, 255, 0.1);
  --border-color-error: rgb(236, 66, 82);

  --progress-primary-color: rgb(236, 66, 82);

  --card-primary-color: rgba(0, 0, 7, 0.45);
  --card-secondary-color: rgba(255, 255, 255, 0.03);
  --card-third-color: rgb(56, 48, 81);
  --card-shadow-color: rgba(0, 0, 0, 0.16);

  --text-color-primary: rgb(255, 255, 255);
  --text-color-secondary: rgba(255, 255, 255, 0.6);
  --text-color-third: rgba(255, 255, 255, 0.502);
  --text-color-active: rgb(236, 66, 82);
  --text-color-disabled: rgba(255, 255, 255, 0.251);

  --icon-color: rgb(255, 255, 255);
  --icon-filter: none;
  --icon-filter-active: invert(45%) sepia(82%) saturate(5797%)
    hue-rotate(336deg) brightness(107%) contrast(85%);

  --select-menu-bg-color: rgb(56, 48, 81);
  --select-menu-list-bg-color: rgb(56, 48, 81);
  --select-menu-list-hover-bg-color: rgba(255, 255, 255, 0.1);

  --table-header-bg-color: rgb(17, 12, 32);
  --table-body-row-bg-color: rgba(255, 255, 255, 0.05);

  --skeleton-bg-color: rgba(255, 255, 255, 0.03);
}
.light {
  --material-theme: rgb(255, 255, 255);
  --mui-text-color: rgb(56, 56, 116);
  --select-menu-bg-color: rgba(85, 4, 217, 0.1);
  --select-menu-list-bg-color: rgb(255, 255, 255);
  --select-menu-list-hover-bg-color: rgba(56, 56, 116, 0.05);
  --border-color: rgba(0, 0, 0, 0.1);
  --text-color-primary: rgb(56, 56, 116);
  --text-color-secondary: rgba(56, 56, 116, 0.6);
  --bg-image: url(../assets/bg.webp);
  --button-primary-color: rgba(0, 0, 0, 0.1);
  --button-disabled-color: rgba(0, 0, 0, 0.2);
  --bg-color-primary: rgba(255, 255, 255, 0.8);
  --table-header-bg-color: rgb(203, 187, 239);
  --table-body-row-bg-color: rgba(0, 0, 0, 0.05);
  --skeleton-bg-color: rgba(85, 4, 217, 0.1);
}

body {
  margin: 0;
  font-size: var(--text-font-size-base);
  font-family: var(--font-family-normal);
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--text-color-primary);
  background-image: var(--bg-image);
}
body.light {
  background-color: var(--bg-color-primary-light);
}
body.dark {
  background-color: var(--bg-color-primary-dark);
}
.MuiDataGrid-menu .MuiPaper-root {
  background-color: var(--color-background-dropdowns) !important;
  color: #fff;
}
.MuiDataGrid-panelWrapper {
  background-color: var(--color-background-dropdowns) !important;
  color: #fff !important;
}
.MuiNativeSelect-select * {
  background-color: var(--color-background-dropdowns) !important;
  color: #fff !important;
}
.MuiDataGrid-panelWrapper * {
  color: #fff !important;
}
.MuiDataGrid-root *:focus {
  border: none !important;
  outline: none !important;
}
.MuiInputBase-root *:focus {
  border-color: #ff4b5c !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

::-moz-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(128, 128, 128, 0.5) !important;
  border-radius: 10px !important;
}

::-ms-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a4365;
  border-radius: 10px;
  cursor: pointer !important;
}

::-moz-scrollbar-thumb {
  background: #4a4365;
  border-radius: 10px;
  cursor: pointer !important;
}

::-ms-scrollbar-thumb {
  background: #4a4365;
  border-radius: 10px;
  cursor: pointer !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 4, 217, 0.9);
}

::-moz-scrollbar-thumb:hover {
  background: rgba(85, 4, 217, 0.9);
}

::-ms-scrollbar-thumb:hover {
  background: rgba(85, 4, 217, 0.9);
}

rect {
  stroke: var(--color-background-primary);
  stroke-width: 4;
}

.rdb-custom-cell {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.SortableHelperWithOverride {
  cursor: grabbing !important;
  pointer-events: auto !important;
  z-index: 1300;
}
